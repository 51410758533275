<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
export default {
    name: "BoCustomerActivities",
    extends: Base,
    data() {
        return {
            Name: "BoCustomerActivities",
            PostCategory: [],
        }
    },
    mounted() {
        this.$set(this.$root, 'page', this)
        this.refreshData()
    },
    watch: {
        '$route.query'(v) {
            this.refreshData()
        },
    }
};
</script>
<template>
<div class="container-fluid">
    <PageTitle></PageTitle>

    <!-- ========================================================================
    LIST DATA
    ============================================================================= -->
    <div class="panel panel-flat" v-if="!$route.params.id">
        <div class="panel-body panel-filter">
            <VForm @resp="search">
            <div class="row">
                <div class="col-sm-3">
                    <h3 class="box-title">{{ObjectName}} List <span
                    v-if="data.total">({{(data.total||0).format()}})</span></h3>
                </div>
                <div class="col-sm-5"></div>
                <div class="col-sm-3">
                    <div class="has-feedback has-feedback-left">
                        <input type="search" v-model="filter.search" class="form-control" placeholder="Type and hit Enter">
                        <div class="form-control-feedback">
                            <i class="icon-search4 text-size-base text-muted"></i>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1">
                    <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                </div>
            </div>
            </VForm>
        </div>
        <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>
                            <SortField name="ap_title"></SortField>
                        </th>
                        <th>{{fields.ap_author_name}}</th>
                        <th>{{fields.ap_publish_date}}</th>
                        <th>{{fields.ap_count}}</th>
                        <th>{{fields.ap_is_active}}</th>
                        <th width="120px">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in data.data" :key="k">
                        <td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
                        <td>{{v.ap_title}}</td>
                        <td>{{v.ap_author_name}}</td>
                        <td>{{(v.ap_publish_date||"").dates("format")}}</td>
                        <td>{{v.ap_count}}</td>
                        <td>
                            <StatusLabel :status="v.status"></StatusLabel>
                        </td>
                        <td class="btn-action">
                            <router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'">
                            <i class="icon-pencil"></i></router-link>
                            <a href="javascript:;" class="icon_action" @click="changeStatus(k)"
                            v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
                            <a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
                            v-tooltip="'Remove'"><i class="icon-trash"></i></a>
                        </td>
                    </tr>
                    <tr v-if="NotFound">
                        <td colspan="99">
                            <h3 class="tc">{{NotFound}}</h3>
                        </td>
                    </tr>
                    <tr v-if="data.data===false">
                        <td colspan="99">
                            <LoadingSpinner light></LoadingSpinner>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="panel-body">
            <div class="pull-right">
                <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
            </div>
        </div>
    </div>

    <!-- ========================================================================
    FORM CRUD
    ============================================================================= -->
    <div class="panel panel-flat" v-if="$route.params.id">
        <VForm @resp="submitForm" method="post">
            <div class="panel-heading">
                <h2 class="panel-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h2>
            </div>
            <div class="panel-body">
                <div class="info"></div>
                <div class="row">
                    <div class="col-sm-6">
                        <BoField name="ap_author_name" v-model="row.ap_author_name"></BoField>
                        <BoField name="ap_title" v-model="row.ap_title"></BoField>
                        <BoField name="ap_tags">
                            <TagsInput name="ap_tags" suggestUrl="/select-tags-article" v-model="row.ap_tags" :attr="validation('ap_tags')">
                            </TagsInput>
                        </BoField>
                    </div>
                    <div class="col-sm-6">
                        <BoField name="ap_is_featured">              
                            <div class="radio-group">
                                <radio name="ap_is_featured" v-model="row.ap_is_featured" option="Y"
                                :attr="validation('ap_is_featured')">Yes</radio>
                                <radio name="ap_is_featured" v-model="row.ap_is_featured" option="N">No</radio>
                            </div>
                        </BoField>
                        <!-- <BoField name="ap_cat">
                            <select2 name="ap_cat" v-bind="validation('ap_cat')" :options="PostCategory"
                                :object="['ppc_id','ppc_title']" v-model="row.ap_cat" class="form-control">
                                <option value="">-- Select Category --</option>
                            </select2>
                        </BoField> -->
                        <BoField name="ap_photo" info="Orientasi foto landscape, dengan lebar max. 800px">
                            <Uploader name="ap_photo" type="article" uploadType="gallery" v-model="row.ap_photo">
                            </Uploader>
                        </BoField>
                        
                        <BoField name="ap_publish_date">
                            <DatePicker name="ap_publish_date" v-model="row.ap_publish_date"
                                v-bind="validation('ap_publish_date')"></DatePicker>
                        </BoField>
                    </div>
                    <div class="col-xs-12">
                        <BoField name="ap_desc">
                            <CKEditor name="ap_desc" v-model="row.ap_desc" v-bind="validation('ap_desc')">
                            </CKEditor>
                        </BoField>
                    </div>
                    <div class="col-sm-6">
                        <BoField name="ap_seo_keyword">
                            <TagsInput name="ap_seo_keyword" suggestUrl="/select-tags-article" v-model="row.ap_seo_keyword"
                                :attr="validation('ap_seo_keyword')"></TagsInput>
                        </BoField>
                        <BoField name="ap_is_active">              
                            <div class="radio-group">
                                <radio name="ap_is_active" v-model="row.ap_is_active" option="Y"
                                :attr="validation('ap_is_active')">Active</radio>
                                <radio name="ap_is_active" v-model="row.ap_is_active" option="N">Inactive</radio>
                            </div>
                        </BoField>
                    </div>
                    <div class="col-sm-6">
                        <BoField name="ap_seo_desc">
                            <textarea rows="4" class="form-control" name="ap_seo_desc" v-model="row.ap_seo_desc" v-bind="validation('ap_seo_desc')"></textarea>
                        </BoField>
                    </div>
                    <div class="col-xs-12">
                        <div class="text-right">
                            <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </VForm>
    </div>
</div>
</template>